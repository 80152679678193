import Splide from '@splidejs/splide';
import A11yDialog from 'a11y-dialog';
import GLightbox from 'glightbox';
import Disclosure from './_disclosure';
import A11yHamburgerNav from './_a11yHamburgerNav';
import initMap from './_map';
import InitPagination from './_pagination';
import TabsManual from './_tabsManual';  
import FormValidate from './_formValidate';

import {
	OverlayScrollbars,
	ScrollbarsHidingPlugin,
	SizeObserverPlugin
} from 'overlayscrollbars';

function initDisclo() {
	// Disclosure
	const disclos = document.querySelectorAll('.disclosure button.openDisclosure');
	disclos.forEach((btn) => {
		const discloItem = new Disclosure(btn);
		discloItem.init();
	});
}

function initMenus() {
	const a11yNavs = document.querySelectorAll('.a11y-hamburger-nav');
	a11yNavs.forEach((menu) => {
		const a11yNavItem = new A11yHamburgerNav(menu);
		a11yNavItem.init();
	});
}

function initModals() {
	const modals = document.querySelectorAll('.dialog');
	modals.forEach((modal) => {
		const dialog = new A11yDialog(modal);
		dialog
			.on('show', () => (document.documentElement.style.overflowY = 'hidden'))
			.on('hide', () => (document.documentElement.style.overflowY = ''))
	});
}

function toggleAnchorBtn() {

	const anchorid = this.dataset.id;
	const section = document.querySelector('#' + anchorid);
	const bigBtn = document.querySelector('#btn_' + anchorid);


	if (this.classList.contains('opened')) {
		// on ferme
		section.setAttribute('hidden', 'true');
		bigBtn.setAttribute('aria-pressed', false);
		this.classList.remove('opened');
		this.classList.add('closed');
	} else {
		// on ouvre
		section.removeAttribute('hidden');
		bigBtn.setAttribute('aria-pressed', true);
		this.classList.remove('closed');
		this.classList.add('opened');
	}
}

function toggleAnchorBigBtn() {
	const anchorid = this.dataset.id;
	const section = document.querySelector('#' + anchorid);
	const btn = document.querySelector('#btn_min_' + anchorid);

	if (this.getAttribute('aria-pressed') === 'true') {
		// on ferme
		section.setAttribute('hidden', 'true');
		this.setAttribute('aria-pressed', false);
		btn.classList.remove('opened');
		btn.classList.add('closed');
	} else {
		// on ouvre
		section.removeAttribute('hidden');
		this.setAttribute('aria-pressed', true);
		btn.classList.remove('closed');
		btn.classList.add('opened');
	}
}


function initLightBox() {
	const prefersReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)');
	let effect = 'zoom';
	let slide = 'slide';
	let trigger = document;

	if (prefersReducedMotion.matches) {
		effect = 'fade';
		slide = 'fade';
	}

	const lightbox = GLightbox({
		loop: true,
		autoplayVideos: false,
		videosWidth: '1024px',
		openEffect: effect,
		closeEffect: effect,
		slideEffect: slide
	});

	lightbox.on('open', () => {
		trigger = document.activeElement;
	});
	lightbox.on('close', () => {
		trigger.focus();
	});
}


document.addEventListener('DOMContentLoaded', () => {
	document.body.classList.remove('no-js');
	document.body.classList.add('js');

	// Disclo
	initDisclo();

	// Menu
	initMenus();

	// MAP
	initMap();

	// 
	initModals();

	initLightBox();

	// SLIDES
	const slides = document.getElementsByClassName('mobileSplide');
	for (let i = 0; i < slides.length; i++) {
		new Splide(slides[i], {
			mediaQuery: 'min',
			perPage: 1,
			gap: '1rem',
			breakpoints: {
				460: {
					perPage: 2,
				},
				720: {
					destroy: true,
				},
			}
		}).mount();
	}

	const dlSlides = document.querySelectorAll('.dl-blocks.splide');
	dlSlides.forEach((elt) => {
		new Splide(elt, {
			perPage: 3,
			width: '65rem',
			gap: '1rem',
			breakpoints: {
				900: {
					perPage: 2,
					focus: 0,
					gap: '1rem',
				},
				640: {
					perPage: 1,
				}
			}
		}).mount();
	});



	// thumbs
	const thumbs = document.getElementsByClassName('thumbSplide');
	const mains = document.getElementsByClassName('corpoSplide');
	for (var j = 0; j < thumbs.length; j++) {
		var thumb = new Splide(thumbs[j], {
			fixedWidth: 125,
			gap: 10,
			rewind: true,
			pagination: false,
			arrows: false,
			isNavigation: true,
			breakpoints: {
				1200: {
					arrows: true,
					fixedWidth: 100,
				},
				990: {
					// destroy: true
				}
			}
		});

		var main = new Splide(mains[j], {
			type: 'fade',
			gap: 10,
			pagination: false,
			arrows: false,
			autoHeight: true,
			breakpoints: {
				990: {
					arrows: true,
					pagination: true
				}
			}
		});

		main.sync(thumb);
		main.mount();
		thumb.mount();

	}


	const dates = document.querySelectorAll('.date-slider');
	dates.forEach((elt) => {
		let firstIndex = 0;
		if (document.querySelector('.splide__slide.current')) {
			firstIndex = document.querySelector('.splide__slide.current').getAttribute('data-cpt');
			firstIndex = parseInt(firstIndex);
		}
		new Splide(elt, {
			perPage: 3,
			gap: '0.5rem',
			rewind: 'true',
			width: '19rem',
			padding: { left: 10, right: 10 },
			pagination: false,
			breakpoints: {
				480: {
					perPage: 2,
					width: '16rem',
				}
			}
		}).mount().go(firstIndex);
	});


	const news = document.querySelectorAll('.news-slider');
	news.forEach((elt) => {
		new Splide(elt, {
			gap: '1rem',
			rewind: 'true',
			width: '100%' 
		}).mount();
	});

	// TABS LISTS
	var tablists = document.querySelectorAll('.tablist[role=tablist]');
	tablists.forEach((tab) => {
		new TabsManual(tab).init();
	});


	// anchors btn
	const anchors = document.querySelectorAll('.anchor-actions__btn');
	const bigBtns = document.querySelectorAll('.js-show-section');
	anchors.forEach((btn) => {
		btn.addEventListener('click', toggleAnchorBtn.bind(btn));
	});

	bigBtns.forEach((btn) => {
		btn.addEventListener('click', toggleAnchorBigBtn.bind(btn));
	});


	// scrollbars js-scrollbar
	const scrolls = document.querySelectorAll('.js-scrollbar');
	if (scrolls) {
		OverlayScrollbars.plugin([SizeObserverPlugin, ScrollbarsHidingPlugin]);
		scrolls.forEach((elt) => {
			OverlayScrollbars(elt, {
				scrollbars: {
					theme: 'os-theme-light',
				}
			});
		});
	}

	const scrollsDark = document.querySelectorAll('.js-scrollbar-dark');
	if (scrollsDark) {
		OverlayScrollbars.plugin([SizeObserverPlugin, ScrollbarsHidingPlugin]);
		scrollsDark.forEach((elt) => {
			OverlayScrollbars(elt, {
				scrollbars: {
					theme: 'os-theme-dark',
				}
			});
		});
	}


	if (document.getElementById("paginated-list")) {
		const pages = new InitPagination();
		pages.init();
	}
	

	// FORM
	const domForm = document.querySelector('.js-rsvpForm');
	if (domForm) {
		new FormValidate(domForm, 'multiple');
	}

	const formResult = document.querySelector('.formResultMessage > .alert');
	if (formResult) {
		window.setTimeout(() => {
			formResult.parentElement.focus();
		}, 100);
	}

	const check = document.getElementById('Inputfield_je_serai_accompagne_e');
	const inputToRequired = document.querySelectorAll('.InputfieldStateRequiredIf .required');
	check.addEventListener('change', () => {
		if (check.checked) {
			inputToRequired.forEach((e) => {
				e.setAttribute('required','required');
			});
		} else {
			inputToRequired.forEach((e) => {
				e.removeAttribute('required');
			});
		}
	});

	

});