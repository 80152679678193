
/**
* @constructor
* Implements a minimal form validation
*
* @param {Element} form The form element.
* @param {string} type - global error message or individual error message (unique|multiple)
*/
export default function FormValidate(form, type = 'unique') {
	this.validationType = type;
	this.form = form;
	this.form.noValidate = true;
	this.globalMessage = '';
	this.globalErrorElt = document.querySelector('.formErrorMessage');
	this.nbError = 0;
	this.submitButton = this.form.querySelector('button[type=submit]');
	this.emailErrorMessage = "Adresse e-mail invalide.<br>Exemple : nom@domaine.fr.";
	if (document.documentElement.lang == 'en') {
		this.emailErrorMessage = "Invalid email address.<br>Example: name@domain.fr.";
	}
	this.requiredErrorMessage = "Veuillez renseigner ce champ.";
	if (document.documentElement.lang == 'en') {
		this.requiredErrorMessage = "Please fill out this field.";
	}

	// form submit event
	this.form.addEventListener('submit', (e) => this.validateForm(e));


	this.validateForm = (e) => {

		if (this.submitButton.classList.contains('loading')) return;

		// prevent new submit
		this.submitButton.classList.add('loading');

		const fields = Array.from(this.form.elements);

		// Reset
		this.reset(fields);
		this.nbError = 0;

		this.addSrLoading();

		if (this.form.checkValidity()) {
			// Form valid, trigger event
			this.form.dispatchEvent(new Event('validated'));
		} else {

			// prevent submit
			e.preventDefault();
			e.stopImmediatePropagation();

			// Check fields
			fields.forEach((f) => {
				if (f.getAttribute('type') !== 'submit' && f.getAttribute('type') !== 'hidden') {

					if (!f.checkValidity()) {

						if (f.getAttribute('type') == 'email' && f.validity.typeMismatch) {
							f.setCustomValidity(this.emailErrorMessage);
						}
						if (f.validity.valueMissing) {
							f.setCustomValidity(this.requiredErrorMessage);
						}

						// field is invalid
						this.unvalidateField(f);
						this.nbError += 1;
					} else {
						// field is valid
						this.validateField(f);
					}
				}
			});

			if (this.validationType === 'unique') {
				// show error message
				this.showGlobal();
			} else {
				// show message only for screen reader
				this.showMultiple();
			}

			this.submitButton.classList.remove('loading');

		}
	};

	this.reset = (fields) => {
		this.globalMessage = '<ol>';
		this.globalErrorElt.innerHTML = '';
		document.querySelector('.formResultMessage').innerHTML = '';

		fields.forEach((f) => {
			if (f.getAttribute('type') !== 'submit' && f.getAttribute('type') !== 'hidden') {
				this.validateField(f);
			}
		});
	};

	this.validateField = (f) => {
		f.setCustomValidity('');
		f.setAttribute('aria-invalid', false);
		if (f.parentElement.querySelector('.indiError')) {
			f.parentElement.querySelector('.indiError').remove();
		}
	};

	this.unvalidateField = (f) => {
		f.setAttribute('aria-invalid', true);

		if (this.validationType === 'unique') {
			this.addErrorToField(f, 'visually-hidden-all');
			this.addErrorToGlobal(f);
		} else {
			this.addErrorToField(f);
			this.addErrorToGlobal(f);
		}

	};

	this.getErrorMessage = (f) => {
		let em = f.validationMessage;
		return em;
	};

	this.getErrorsTitle = () => {
		let title = "Le formulaire comporte des erreurs";
		if (document.documentElement.lang == 'en') {
			title = "The form has errors";
		}

		if (this.nbError > 0) {
			title = `Le formulaire comporte ${this.nbError} erreurs`;
			if (document.documentElement.lang == 'en') {
				title = `The form has ${this.nbError} errors`;
			}
		}
		return title;
	}

	this.addErrorToGlobal = (f) => {
		const message = `<li><a href='#${f.getAttribute('id')}'>${f.getAttribute('data-name')} : ${this.getErrorMessage(f)}</a></li>`;
		this.globalMessage = `${this.globalMessage} ${message}`;
	};

	this.addErrorToField = (f, c = '') => {
		let parent = f.parentElement;
		if (f.nodeName == "SELECT") {
			parent = f.parentElement.parentElement;	
		}

		const errorId = `error_${f.getAttribute('name')}`;
		const errorBlock = document.createElement('div');
		errorBlock.id = errorId;
		errorBlock.className = `indiError ${c}`;
		errorBlock.innerHTML = `${f.getAttribute('data-name')} : ${this.getErrorMessage(f)}`;

		f.setAttribute('aria-describedby', errorId);
		parent.appendChild(errorBlock);
	};


	this.showGlobal = () => {
		this.deleteSrLoading();

		this.globalMessage = `<h3 id='error-title'>${this.getErrorsTitle()}</h3>${this.globalMessage}</ol>`;
		const er = document.createElement('div');
		er.className = 'alert alert--error';
		er.innerHTML = this.globalMessage;
		this.globalErrorElt.appendChild(er);
		this.globalErrorElt.focus();
	};

	this.showMultiple = () => {
		this.deleteSrLoading();

		const er = document.createElement('div');
		er.className = 'alert alert--error';
		er.innerHTML = `<h3 id='error-title' tabindex=-1 >${this.getErrorsTitle()}</h3>${this.globalMessage}</ol>`;
		this.globalErrorElt.appendChild(er);
		this.globalErrorElt.setAttribute('aria-labelledby', 'error-title');
		document.getElementById('error-title').focus();

		// document.querySelector('.form__status').appendChild(er);

		// //focus sur le premier input faux
		// const firstInvalidField = this.form.querySelector(':invalid');
		// if (firstInvalidField) {
		// 	firstInvalidField.focus();
		// }

	};

	this.addSrLoading = () => {
		const ms = document.querySelector('.form__status');
		ms.innerHTML = 'Being processed';
	};

	this.deleteSrLoading = () => {
		this.globalErrorElt.innerHTML = '';

		const ms = document.querySelector('.form__status');
		ms.innerHTML = '';
	};
}
