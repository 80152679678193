// var getSiblings = function (elem) {

// 	// Setup siblings array and get the first sibling
// 	var siblings = [];
// 	var sibling = elem.parentNode.firstChild;

// 	// Loop through each sibling and push to the array
// 	while (sibling) {
// 		if (sibling.nodeType === 1 && sibling !== elem) {
// 			siblings.push(sibling);
// 		}
// 		sibling = sibling.nextSibling
// 	}

// 	return siblings;

// };


var setPosition = function (pic, elem) {
	const bounds = pic.getBoundingClientRect();
	const parentBounds = document.querySelector('.mapWrapper').getBoundingClientRect();

	const left = bounds.left - parentBounds.left;
	const top = bounds.top - parentBounds.top;

	elem.style.left = left + 'px';
	elem.style.top = top + 'px';
	
};


export default function initMap() {
	const zones = document.querySelectorAll('.cls-9, .cls-11, .cls-12, .cls-14, .cls-15, .cls-20, .cls-21, .map__item');

	zones.forEach((elt) => {
		elt.addEventListener('mouseenter', (e) => {
			e.stopImmediatePropagation();

			const continent = elt.getAttribute('id');
			const countries = document.querySelectorAll('.' + continent);
			const item = document.querySelector('.map__item#' + continent);

			countries.forEach((c) => {
				if (!c.classList.contains('hover')) {
					c.classList.add('hover');
				}
			});

			if (item && !item.classList.contains('visible')) {
				document.querySelectorAll('.map__item.visible').forEach((m) => { m.classList.remove('visible'); });
				setPosition(document.querySelector('.cls-21.' + continent), item);
				item.classList.add('visible');
			}

		});

		elt.addEventListener('mouseleave', () => {
			document.querySelectorAll('.hover').forEach((elt) => {
				elt.classList.remove('hover');
			});

			document.querySelectorAll('.map__item.visible').forEach((m) => { m.classList.remove('visible'); });
		});
	});
}