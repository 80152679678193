/**
* @constructor
* Implements a minimal disclosure element
*
* @param {Element} buttonNode The disclosure open button element.
*/

export default function Disclosure(buttonNode) {

	this.OpenButtonNode = buttonNode;
	this.disclosureElt = this.OpenButtonNode.parentNode;
	this.closeButtonNode = this.disclosureElt.querySelector('.closeDisclosure');
	this.controlledNode = false;
	const id = this.OpenButtonNode.getAttribute('aria-controls');


	if (id) {
		this.controlledNode = document.getElementById(id);
	}

	this.showContent = () => {
		if (this.controlledNode) {
			this.controlledNode.classList.add('open');
			this.controlledNode.setAttribute('aria-hidden', 'false');
		}
	};

	this.hideContent = () => {
		if (this.controlledNode) {
			this.controlledNode.classList.remove('open');
			this.controlledNode.setAttribute('aria-hidden', 'true');
		}
	};

	this.hideOpenedContent = (button) => {
		button.parentElement.classList.remove('expanded');
		button.setAttribute('aria-expanded', 'false');
		button.nextElementSibling.classList.remove('open');
		button.nextElementSibling.setAttribute('aria-hidden', 'true');
	}

	this.toggleExpand = () => {



		if (this.OpenButtonNode.getAttribute('aria-expanded') === 'true') {
			this.disclosureElt.classList.remove('expanded');
			this.OpenButtonNode.setAttribute('aria-expanded', 'false');
			this.hideContent();

			if (this.closeButtonNode) {
				this.closeButtonNode.setAttribute('aria-expanded', 'false');
				this.closeButtonNode.setAttribute('aria-hidden', 'true');
			}


		} else {

			const openedToggle = document.querySelector('.openDisclosure[aria-expanded="true"]');
			if (openedToggle) {
				this.hideOpenedContent(openedToggle);
			}

			this.disclosureElt.classList.add('expanded');
			this.OpenButtonNode.setAttribute('aria-expanded', 'true');
			this.showContent();

			if (this.closeButtonNode) {
				this.closeButtonNode.setAttribute('aria-expanded', 'true');
				this.closeButtonNode.setAttribute('aria-hidden', 'false');
			}
		}
	};

	this.init = () => {
		this.OpenButtonNode.addEventListener('click', this.toggleExpand);
		this.controlledNode.setAttribute('aria-hidden', 'true');
		if (this.closeButtonNode) {
			this.closeButtonNode.addEventListener('click', this.toggleExpand);
		}
	};

}
