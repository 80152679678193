/**
* @constructor
* Implements a minimal pagination
*
* @param {groupNode} groupNode 
*/

export default function InitPagination() {
	const paginationNumbers = document.getElementById("pagination-numbers");
	const paginatedList = document.getElementById("paginated-list");
	const listItems = paginatedList.querySelectorAll(".paginated-item");
	const nextButton = document.getElementById("next-button");
	const prevButton = document.getElementById("prev-button");


	const paginationLimit = 12;
	const pageCount = Math.ceil(listItems.length / paginationLimit);
	let currentPage;

	this.appendPageNumber = (index) => {
		const pageNumber = document.createElement("button");
		pageNumber.className = "pagination-number";
		pageNumber.innerHTML = index;
		pageNumber.setAttribute("page-index", index);
		pageNumber.setAttribute("aria-label", "Page " + index);
		paginationNumbers.appendChild(pageNumber);
	};

	this.getPaginationNumbers = () => {
		for (let i = 1; i <= pageCount; i++) {
			this.appendPageNumber(i);
		}
	};

	this.handleActivePageNumber = () => {
		document.querySelectorAll(".pagination-number").forEach((button) => {
			button.classList.remove("active");

			const pageIndex = Number(button.getAttribute("page-index"));
			if (pageIndex == currentPage) {
				button.classList.add("active");
			}
		});
	};

	this.setCurrentPage = (pageNum) => {
		currentPage = pageNum;
		this.handleActivePageNumber();
		this.handlePageButtonsStatus();

		const prevRange = (pageNum - 1) * paginationLimit;
		const currRange = pageNum * paginationLimit;
		listItems.forEach((item, index) => {
			item.classList.add("hidden");
			if (index >= prevRange && index < currRange) {
				item.classList.remove("hidden");
			}
		});
	};


	this.disableButton = (button) => {
		button.classList.add("disabled");
		button.setAttribute("disabled", true);
	};
	this.enableButton = (button) => {
		button.classList.remove("disabled");
		button.removeAttribute("disabled");
	};
	this.handlePageButtonsStatus = () => {
		if (currentPage === 1) {
			this.disableButton(prevButton);
		} else {
			this.enableButton(prevButton);
		}
		if (pageCount === currentPage) {
			this.disableButton(nextButton);
		} else {
			this.enableButton(nextButton);
		}
	};

	this.init = () => {
		this.getPaginationNumbers();
		this.setCurrentPage(1);

		prevButton.addEventListener("click", () => {
			this.setCurrentPage(currentPage - 1);
		});

		nextButton.addEventListener("click", () => {
			this.setCurrentPage(currentPage + 1);
		});

		document.querySelectorAll(".pagination-number").forEach((button) => {
			const pageIndex = Number(button.getAttribute("page-index"));
			if (pageIndex) {
				button.addEventListener("click", () => {
					this.setCurrentPage(pageIndex);
				});
			}
		});
	};
}