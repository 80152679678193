/**
* @constructor
* Implements a minimal hamburger menu without submenu, with focus blocage
*
* @param {Element} menu The menu element.
*/
export default function A11yHamburgerNav(menu) {
	// Define value for keycodes
	const VK_ESC = 27;

	this.container = menu.parentElement; // The nav element
	this.trigger = this.container.querySelector('.a11y-nav-trigger'); // the hamburger button
	this.closeButton = this.container.querySelector('.a11y-close-menu');
	this.currentMenuItem = false;
	this.firstFocusableElement = "";
	this.lastFocusableElement = "";

	// Initialisation
	this.init = () => {
		this.menuSetup();
	};

	this.setupNavContainerAria = () => {
		this.trigger.setAttribute('aria-expanded', false);
		menu.setAttribute('aria-hidden', true);
	};

	this.closeOnOutsideClick = (e) => {
		if (e.target.closest('.a11y-hamburger-nav-block') === null) {
			if (this.trigger.getAttribute('aria-expanded') === 'true') {
				this.closeNav();
			}
		}
	};

	// Open / Close main Nav
	this.toggleNavContainer = () => {

		if (this.trigger.getAttribute('aria-expanded') === 'false') {
			// open the nav, focus on the nav

			
			document.body.setAttribute('data-burger-menu-status', 'opened');
			this.trigger.setAttribute('aria-expanded', true);
			menu.setAttribute('aria-hidden', false);

			document.addEventListener('click', this.closeOnOutsideClick);

			menu.focus();
		} else {
			
			// hide, focus on the trigger
			this.trigger.setAttribute('aria-expanded', false);
			menu.setAttribute('aria-hidden', true);
			document.body.setAttribute('data-burger-menu-status', 'closed');

			document.removeEventListener('click', this.closeOnOutsideClick);

			this.trigger.focus();
		}

	};

	this.closeNav = () => {
		
		this.trigger.setAttribute('aria-expanded', false);
		menu.setAttribute('aria-hidden', true);
		document.body.setAttribute('data-burger-menu-status', 'closed');
		this.trigger.focus();
	}

	this.keyboardManager = (e) => {
		// Check for TAB key press
		if (e.keyCode === 9) {

			// SHIFT + TAB
			if (e.shiftKey) {
				if (document.activeElement === this.firstFocusableElement) {
					e.preventDefault();
					this.lastFocusableElement.focus();
				}

				// TAB
			} else {
				if (document.activeElement === this.lastFocusableElement) {
					e.preventDefault();
					this.firstFocusableElement.focus();
				}
			}
		}

		// ESCAPE
		if (e.keyCode === VK_ESC) {
			this.closeNav();
		}

	};


	// Modify Menu Markup & Bind Listeners
	this.menuSetup = () => {
		this.container.classList.remove('no-js');
		this.container.classList.add('with-js');

		this.setupNavContainerAria();

		// Setup focus trap
		const focusableElements = menu.querySelectorAll('a, button');
		this.firstFocusableElement = focusableElements[0];
		this.lastFocusableElement = focusableElements[focusableElements.length - 1];

		// Bind event listener
		this.trigger.addEventListener('click', this.toggleNavContainer);
		this.closeButton.addEventListener('click', this.closeNav);

		// Bind keyboard listener to nav
		this.container.addEventListener('keydown', this.keyboardManager.bind(this));

	}
}