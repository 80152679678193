/**
* @constructor
* Implements a minimal tabList element
*
* @param {groupNode} groupNode 
*/

export default function TabsManual(groupNode) {
	this.tablistNode = groupNode;

	this.tabs = [];

	this.firstTab = null;
	this.lastTab = null;

	this.tabs = Array.from(this.tablistNode.querySelectorAll('[role=tab]'));
	this.tabpanels = [];


	this.setSelectedTab = (currentTab) => {
		for (var i = 0; i < this.tabs.length; i += 1) {
			var tab = this.tabs[i];
			if (currentTab === tab) {
				tab.setAttribute('aria-selected', 'true');
				tab.removeAttribute('tabindex');
				this.tabpanels[i].removeAttribute('aria-hidden');
			} else {
				tab.setAttribute('aria-selected', 'false');
				tab.tabIndex = -1;
				this.tabpanels[i].setAttribute('aria-hidden', true);
			}
		}
	};

	this.moveFocusToTab = (currentTab) => {
		currentTab.focus();
	};

	this.moveFocusToPreviousTab = (currentTab) => {
		var index;

		if (currentTab === this.firstTab) {
			this.moveFocusToTab(this.lastTab);
		} else {
			index = this.tabs.indexOf(currentTab);
			this.moveFocusToTab(this.tabs[index - 1]);
		}
	};

	this.moveFocusToNextTab = (currentTab) => {
		var index;

		if (currentTab === this.lastTab) {
			this.moveFocusToTab(this.firstTab);
		} else {
			index = this.tabs.indexOf(currentTab);
			this.moveFocusToTab(this.tabs[index + 1]);
		}
	};

	/* EVENT HANDLERS */

	this.keyboardManager = (e) => {
		var tgt = e.currentTarget,
			flag = false;

		switch (e.key) {
			case 'ArrowLeft':
				this.moveFocusToPreviousTab(tgt);
				flag = true;
				break;

			case 'ArrowRight':
				this.moveFocusToNextTab(tgt);
				flag = true;
				break;

			case 'Home':
				this.moveFocusToTab(this.firstTab);
				flag = true;
				break;

			case 'End':
				this.moveFocusToTab(this.lastTab);
				flag = true;
				break;

			default:
				break;
		}

		if (flag) {
			e.stopPropagation();
			e.preventDefault();
		}
	};

	this.onClick = (e) => {
		this.setSelectedTab(e.currentTarget);
	};

	this.init = () => {
		for (var i = 0; i < this.tabs.length; i += 1) {
			var tab = this.tabs[i];
			var tabpanel = document.getElementById(tab.getAttribute('aria-controls'));

			tab.tabIndex = -1;
			tab.setAttribute('aria-selected', 'false');
			this.tabpanels.push(tabpanel);

			tab.addEventListener('keydown', this.keyboardManager.bind(this));
			tab.addEventListener('click', this.onClick.bind(this));

			if (!this.firstTab) {
				this.firstTab = tab;
			}
			this.lastTab = tab;
		}

		this.setSelectedTab(this.firstTab);
		this.tablistNode.addEventListener('keydown', this.keyboardManager.bind(this));
		this.tabs.forEach((tab) => {
			tab.addEventListener('click', (e) => {
				this.setSelectedTab(e.currentTarget);
			});
		});

	}

}